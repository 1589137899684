/* eslint-env jquery */
jQuery.fn.exists = function () { return ($(this).length > 0) }
$(function () {
  if ($('#home-cover').exists()) {
    var landingSrc = $('.home-cover-img', '#home-cover-slideshow').first().attr('data-src')
    var showHomeCover
    showHomeCover = function () {
      $('body').addClass('load')
      $('.home-cover-img', '#home-cover-slideshow').first().css('background-image', 'url(' + landingSrc + ')').addClass('animate-in--alt').removeAttr('data-src')
      setTimeout(function () {
        setTimeout(function () {
          $('body').addClass('loaded')
        }, 400 * 3)
        setTimeout(function () {
          showHomeSlideshow()
        }, 7000)
      }, 400 * 1.5)
    }
    var showHomeSlideshowInterval = function () {
      setTimeout(function () {
        showHomeSlideshow()
      }, 8000)
    }
    var showHomeSlideshow
    showHomeSlideshow = function () {
      var $image = $('.home-cover-img[data-src]', '#home-cover-slideshow').first()
      var $images = $('.home-cover-img', '#home-cover-slideshow')
      if ($image.length === 0) {
        if ($images.length === 1) return
        $images.first().removeClass('animate-in')
        $('#home-cover-slideshow').append($images.first())
        setTimeout(function () {
          $('.home-cover-img:last', '#home-cover-slideshow').addClass('animate-in')
        }, 20)
        setTimeout(function () {
          $('.home-cover-img:not(:last)', '#home-cover-slideshow').removeClass('animate-in')
        }, 4000)
        showHomeSlideshowInterval()
      } else {
        var src = $image.attr('data-src')
        $('<img/>').attr('src', src).on('load error', function () {
          $(this).remove()
          $image.css('background-image', 'url(' + src + ')').addClass('animate-in').removeAttr('data-src')
          setTimeout(function () {
            $('.home-cover-img:not(:last)', '#home-cover-slideshow').removeClass('animate-end animate-in--alt')
          }, 2000)
          showHomeSlideshowInterval()
        })
      }
    }
    showHomeCover()
  }
})
